var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex flex-column flex-root" }, [
    _c(
      "div",
      {
        staticClass:
          "login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white",
        class: {
          "login-signin-on": this.state == "signin",
          "login-forgot-on": this.state == "forgot",
        },
        attrs: { id: "kt_login" },
      },
      [
        _c(
          "div",
          {
            staticClass: "login-aside d-flex flex-column flex-row-auto",
            style: { backgroundImage: `url(${_vm.backgroundImage})` },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-column-auto flex-column pt-lg-40 pt-15",
              },
              [
                _c("a", { staticClass: "text-center", attrs: { href: "#" } }, [
                  _c("img", {
                    staticClass: "max-h-100px",
                    attrs: { src: _vm.logoImg, alt: "" },
                  }),
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showInfo,
                        expression: "showInfo",
                      },
                    ],
                    staticClass: "col-12",
                  },
                  [_vm._m(0)]
                ),
              ]
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto",
          },
          [
            _c("div", { staticClass: "d-flex flex-column-fluid flex-center" }, [
              _c("div", { staticClass: "login-form login-signin" }, [
                _c(
                  "form",
                  {
                    staticClass: "form",
                    attrs: {
                      novalidate: "novalidate",
                      id: "kt_login_signin_form",
                    },
                  },
                  [
                    _c("div", { staticClass: "mb-15" }, [
                      _c(
                        "h3",
                        {
                          staticClass:
                            "font-weight-bolder text-dark font-size-h4 text-center",
                        },
                        [_vm._v(" " + _vm._s(_vm.titleUrl) + " ")]
                      ),
                    ]),
                    _c("b-form-group", {
                      staticClass: "text-center mb-15",
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ ariaDescribedby }) {
                            return [
                              _c("b-form-radio-group", {
                                staticClass: "btn-block",
                                attrs: {
                                  options: [
                                    { text: "ÖĞRENCİ", value: "student" },
                                    { text: "VELİ", value: "customer" },
                                  ],
                                  "aria-describedby": ariaDescribedby,
                                  "button-variant": "outline-primary",
                                  size: "lg",
                                  buttons: "",
                                },
                                model: {
                                  value: _vm.selected,
                                  callback: function ($$v) {
                                    _vm.selected = $$v
                                  },
                                  expression: "selected",
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass:
                            "font-size-h6 font-weight-bolder text-dark",
                        },
                        [_vm._v("Kimlik Numarası")]
                      ),
                      _c(
                        "div",
                        {
                          attrs: {
                            id: "example-input-group-1",
                            label: "",
                            "label-for": "example-input-1",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.Email,
                                expression: "form.Email",
                              },
                            ],
                            staticClass:
                              "form-control form-control-solid h-auto py-7 px-6 rounded-lg",
                            staticStyle: { "background-image": "none" },
                            attrs: { type: "text", name: "Email" },
                            domProps: { value: _vm.form.Email },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(_vm.form, "Email", $event.target.value)
                              },
                            },
                          }),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-between mt-n5" },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "font-size-h6 font-weight-bolder text-dark pt-5",
                            },
                            [_vm._v("Şifre")]
                          ),
                          _c(
                            "a",
                            {
                              staticClass:
                                "text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5",
                              attrs: { id: "kt_login_forgot" },
                              on: {
                                click: function ($event) {
                                  return _vm.showForm("forgot")
                                },
                              },
                            },
                            [_vm._v("Şifremi Unuttum")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          attrs: {
                            id: "example-input-group-2",
                            label: "",
                            "label-for": "example-input-2",
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.Password,
                                expression: "form.Password",
                              },
                            ],
                            ref: "password",
                            staticClass:
                              "form-control form-control-solid h-auto py-7 px-6 rounded-lg",
                            staticStyle: { "background-image": "none" },
                            attrs: {
                              type: "password",
                              name: "Password",
                              autocomplete: "off",
                            },
                            domProps: { value: _vm.form.Password },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.form,
                                  "Password",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "pb-5 d-flex justify-content-center" },
                      [
                        _c(
                          "button",
                          {
                            ref: "kt_login_signin_submit",
                            staticClass:
                              "btn btn-block btn-primary font-weight-bolder font-size-h6 px-15 py-4",
                          },
                          [_vm._v(" Giriş Yap ")]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "login-form login-forgot" }, [
                _c(
                  "form",
                  {
                    ref: "kt_login_forgot_form",
                    staticClass: "form",
                    attrs: {
                      novalidate: "novalidate",
                      id: "kt_login_forgot_form",
                    },
                  },
                  [
                    _vm._m(1),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.formForgot.codesent,
                            expression: "!formForgot.codesent",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass:
                              "font-size-h6 font-weight-bolder text-dark",
                          },
                          [_vm._v("Kimlik Numarası")]
                        ),
                        _c(
                          "div",
                          {
                            attrs: {
                              id: "example-input-group-1",
                              "label-for": "example-input-1",
                            },
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.formForgot.CitizenNumber,
                                  expression: "formForgot.CitizenNumber",
                                },
                              ],
                              staticClass:
                                "form-control form-control-solid h-auto py-7 px-6 rounded-lg",
                              attrs: {
                                type: "text",
                                name: "ForgotCitizenNumber",
                                autocomplete: "off",
                              },
                              domProps: { value: _vm.formForgot.CitizenNumber },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.formForgot,
                                    "CitizenNumber",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.formForgot.codesent,
                            expression: "!formForgot.codesent",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass:
                              "font-size-h6 font-weight-bolder text-dark",
                          },
                          [_vm._v("Telefon Numarası")]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formForgot.PhoneNumber,
                              expression: "formForgot.PhoneNumber",
                            },
                          ],
                          staticClass:
                            "form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6",
                          attrs: {
                            type: "text",
                            name: "ForgotPhoneNumber",
                            autocomplete: "off",
                          },
                          domProps: { value: _vm.formForgot.PhoneNumber },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.formForgot,
                                "PhoneNumber",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.formForgot.codesent,
                            expression: "formForgot.codesent",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formForgot.Code,
                              expression: "formForgot.Code",
                            },
                          ],
                          staticClass:
                            "form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6",
                          attrs: {
                            type: "number",
                            placeholder: "Kod",
                            name: "ForgotCode",
                            autocomplete: "off",
                          },
                          domProps: { value: _vm.formForgot.Code },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.formForgot,
                                "Code",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.formForgot.codesent,
                            expression: "formForgot.codesent",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formForgot.Password,
                              expression: "formForgot.Password",
                            },
                          ],
                          staticClass:
                            "form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6",
                          attrs: {
                            type: "password",
                            placeholder: "Yeni şifreniz",
                            name: "ForgotPassword",
                            autocomplete: "off",
                          },
                          domProps: { value: _vm.formForgot.Password },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.formForgot,
                                "Password",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.formForgot.codesent,
                            expression: "formForgot.codesent",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formForgot.ConfirmPassword,
                              expression: "formForgot.ConfirmPassword",
                            },
                          ],
                          staticClass:
                            "form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6",
                          attrs: {
                            type: "password",
                            placeholder: "Tekrar yeni şifreniz",
                            name: "ForgotConfirmPassword",
                            autocomplete: "off",
                          },
                          domProps: { value: _vm.formForgot.ConfirmPassword },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.formForgot,
                                "ConfirmPassword",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "form-group d-flex flex-wrap pb-lg-0" },
                      [
                        _c(
                          "button",
                          {
                            ref: "kt_login_forgot_submit",
                            staticClass:
                              "btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4",
                          },
                          [_vm._v(" Gönder ")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3",
                            attrs: {
                              type: "button",
                              id: "kt_login_forgot_cancel",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.showForm("signin")
                              },
                            },
                          },
                          [_vm._v(" İptal ")]
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-center align-items-center py-7 py-lg-0",
              },
              [
                !_vm.$vuetify.breakpoint.mobile
                  ? _c("div", [_vm._m(2), _vm._m(3)])
                  : _vm._e(),
              ]
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card text-white bg-info mb-3" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("h5", { staticClass: "card-title" }, [_vm._v("Sayın Velimiz")]),
        _c("p", { staticClass: "card-text" }, [
          _vm._v(
            " Uygulamaya ilk giriş için TC Kimlik Numaranızı ve 123456 şifrenizi kullanabilirsiniz. Giriş yaptıktan sonra ayarlar menüsünden şifrenizi değiştirmeyi unutmayınız. "
          ),
        ]),
        _c("p", [
          _vm._v(
            " Veli hesabınıza giriş yaptığınızda alt menüden (Masaüstü bilgisayarlarda sol menüden) öğrencinizi seçip bilgilerini görüntüleyebilirsiniz. "
          ),
        ]),
        _c("p", [
          _vm._v(
            " Detaylı ödev raporuna girmek için menüden Ödev Raporu'na girince açılan ekranda Ödev Karnesi butonuna tıklayabilirsiniz. "
          ),
        ]),
        _c("p", [
          _vm._v(
            " Ödev karnesi ekranında sağ üstteki tarih butonuna tıklayarak açılan ekranda bakmak istediğiniz tarih aralığını seçebilirsiniz. "
          ),
        ]),
        _c("p"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pb-13 pt-lg-0 pt-5" }, [
      _c(
        "h3",
        {
          staticClass:
            "font-weight-bolder text-dark font-size-h4 font-size-h1-lg",
        },
        [_vm._v(" Şifre Sıfırlama ")]
      ),
      _c("p", { staticClass: "text-muted font-weight-bold font-size-h4" }, [
        _vm._v(
          " Kimlik numarası ve telefon bilgisi giriniz. Kimlik numaranızın kayıtlarda bulunması halinde telefonunuza SMS gönderilecektir. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticStyle: { "max-height": "60px" },
        attrs: {
          href: "https://apps.apple.com/tr/app/metaceviz/id1645521965",
          target: "_blank",
          "data-v-1940ce3b": "",
        },
      },
      [
        _c("img", {
          staticStyle: { "max-width": "190px" },
          attrs: { src: "/media/logos/app-store.png" },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "mx-2",
        attrs: {
          href: "https://play.google.com/store/apps/details?id=com.cp.metaceviz",
          target: "_blank",
          "data-v-1940ce3b": "",
        },
      },
      [
        _c("img", {
          staticStyle: { "max-width": "190px" },
          attrs: { src: "/media/logos/google-play.png" },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }